"use client";

import { useEffect, useMemo, useState } from "react";

import {
  CalcContext,
  useAdminAPI,
  useCalculatorInputOptions,
} from "@copmer/calculator-widget";
import { useRouter } from "next/navigation";
import { useSearchParams } from "next/navigation";
import { CalculatorForm, isSet } from "../../_components/form";
import {
  SelectOption,
  SimpleCalculatorFormData,
} from "../../_components/types";
import CalculationResults from "../../_components/CalculationResults";
import { useCalculatorContext } from "../../_components/context";
import { asNumber, parseMode } from "../../_components/utils";
import { CalculatorCopyContextProvider } from "../../_components/copy-context";
import { CopyButton } from "../../_components/CopyButton";
import { cn } from "@/lib/utils";
import { CommodityOption } from "@/actions/products/types";
import { getBasePath } from "@/lib/base-path";

const getInitialValuesFromParams = (
  params: ReturnType<typeof useSearchParams>
) => {
  const mode = parseMode(params.get("mode"));

  const commodity = params.get("commodity") ?? undefined;
  const quantity = asNumber(params, "quantity");
  const tolerance = asNumber(params, "tolerance");
  const hire = asNumber(params, "hire");
  const sfoPrice = asNumber(params, "sfo");
  const mgoPrice = asNumber(params, "mgo");
  const stowageFactorCBFT = asNumber(params, "stowage");

  const startPort = {
    port: params.get("from") ?? undefined,
    cadence: asNumber(params, "f-cadence") ?? undefined,
    terms: params.get("f-terms") ?? undefined,
    draft: asNumber(params, "f-draft") ?? undefined,
    portDA: asNumber(params, "f-da") ?? undefined,
  };

  const endPort = {
    port: params.get("to") ?? undefined,
    cadence: asNumber(params, "t-cadence") ?? undefined,
    terms: params.get("t-terms") ?? undefined,
    draft: asNumber(params, "t-draft") ?? undefined,
    portDA: asNumber(params, "t-da") ?? undefined,
  };

  return {
    mode,
    commodity,
    stowageFactorCBFT,
    quantity,
    tolerance,
    hire,
    sfoPrice,
    mgoPrice,
    startPort,
    endPort,
  };
};

export default function FreightRatePage({
  formClassName,
  baseRoute = "/platform/freight/single",
  extraSearchParams,
  navigateOptions,
  mobileSummaryNodeSelector = "#header-extra",
}: {
  formClassName?: string;
  baseRoute?: string;
  extraSearchParams?: Record<string, string>;
  navigateOptions?: {
    scroll?: boolean;
  };
  mobileSummaryNodeSelector?: string;
}) {
  const contextValue = useCalculatorContext();

  const router = useRouter();
  const searchParams = useSearchParams();

  const {
    isApiLoaded: coreApiLoaded,

    terms,
    ports,
    vesselMap,
  } = useCalculatorInputOptions(contextValue.apiRoutes, null, false);

  const { data: commodities, isLoading: commoditiesLoading } = useAdminAPI<
    CommodityOption[]
  >(`${getBasePath()}/app-api/products/calculator-commodities`);

  const isApiLoaded = coreApiLoaded && !commoditiesLoading;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [payload, setPayload] = useState<SimpleCalculatorFormData | null>(null);

  const urlValues = useMemo(() => {
    return getInitialValuesFromParams(
      searchParams
    ) as any as Partial<SimpleCalculatorFormData>;
  }, [searchParams]);

  const [calcFormSummaryNode, setSummaryNode] = useState<HTMLDivElement | null>(
    null
  );

  const [mobileSummaryNode, setMobileSummaryNode] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(mobileSummaryNodeSelector);

    if (node) {
      setMobileSummaryNode(node);
    }
  }, [mobileSummaryNodeSelector]);

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  return (
    <CalcContext.Provider value={contextValue}>
      <CalculatorCopyContextProvider>
        {isSubmitted ? (
          <div className="flex flex-row pt-10 max-xl:hidden">
            <div className="flex-grow" ref={setSummaryNode} />

            <div className="flex items-center justify-center">
              <CopyButton label />
            </div>
          </div>
        ) : null}

        <div className={cn("flex items-center justify-center", formClassName)}>
          <CalculatorForm
            summaryNode={calcFormSummaryNode}
            mobileSummaryNode={mobileSummaryNode}
            minimized={isSubmitted}
            isLoading={!isApiLoaded}
            commodities={commodities ?? []}
            ports={ports}
            terms={terms}
            urlValues={urlValues}
            onSubmit={(data) => {
              const payload = {
                mode: data.mode,

                commodity: data.commodity,
                quantity: data.quantity,
                tolerance: data.tolerance,
                hire: data.hire,
                sfo: data.sfoPrice,
                mgo: data.mgoPrice,
                stowage: data.stowageFactorCBFT,

                from: data.startPort.port,
                "f-cadence": data.startPort.cadence,
                "f-terms": data.startPort.terms,
                "f-draft": data.startPort.draft,
                "f-da": data.startPort.portDA,

                to: data.endPort.port,
                "t-cadence": data.endPort.cadence,
                "t-terms": data.endPort.terms,
                "t-draft": data.endPort.draft,
                "t-da": data.endPort.portDA,
              };

              const params = new URLSearchParams();

              for (const [key, value] of Object.entries(payload)) {
                if (value !== undefined && value !== "") {
                  params.append(key, `${value}`);
                }
              }

              if (extraSearchParams) {
                for (const [key, value] of Object.entries(extraSearchParams)) {
                  params.append(key, `${value}`);
                }
              }

              router.push(`${baseRoute}?${params}`, navigateOptions);

              setPayload(data);
              setIsSubmitted(true);
            }}
            setSortModalOpen={setSortModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />
        </div>

        <div>
          {isSubmitted && (
            <div className="pt-8">
              <CalculationResults
                hasToleranceParam={isSet(searchParams.get("tolerance"))}
                payload={isSubmitted ? payload : null}
                vesselMap={vesselMap}
                sortModalOpen={sortModalOpen}
                setSortModalOpen={setSortModalOpen}
                filterModalOpen={filterModalOpen}
                setFilterModalOpen={setFilterModalOpen}
              />
            </div>
          )}
        </div>
      </CalculatorCopyContextProvider>
    </CalcContext.Provider>
  );
}
